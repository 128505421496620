<template>
    <div class="home d-flex justify-center flex-column" ref="home">
        <DragLayout />
        <div class="app__footer flex-shrink-0 flex-grow-0">&gt;&gt; {{ desc }} &lt;&lt;</div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import DragLayout from '@/components/homepage/drag-layout';

const { mapState, mapMutations } = createNamespacedHelpers('demo');
import { getBasicConfig } from '../common-settings/basic-config/service';
import { bus } from '../../bus/bus';

export default {
    name: 'Debug',
    components: { DragLayout },
    computed: {},
    data() {
        return {
            desc: 'POWERED BY ISYSCORE'
        };
    },
    created() {
        this.getConfig();
        bus.$on('update-basic-config', this.getConfig);
    },
    methods: {
        getConfig() {
            getBasicConfig().then((res) => {
                this.desc = res.powerby || 'POWERED BY ISYSCORE';
            });
        }
    }
};
</script>

<style scoped lang="scss">
.home {
    width: 100vw;
    height: 100%;
    .app__footer {
        position: fixed;
        bottom: 64px;
        width: 100vw;
        font-size: 12px;
        color: #000;
        font-weight: 400;
        text-align: center;
        line-height: 30px;
        letter-spacing: 2.5px;
    }
}
</style>
