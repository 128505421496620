<template>
    <div>
        <div>
            <div class="layoutJSON" v-if="false">
                Displayed as <code>[x, y, w, h]</code>:
                <div class="columns">
                    <div class="layoutItem" v-for="item in layout" :key="item.i">
                        <b>{{ item.i }}</b
                        >: [{{ item.x }}, {{ item.y }}, {{ item.w }}, {{ item.h }}]
                    </div>
                </div>
            </div>
        </div>
        <br />
        <!-- <div @drag="drag" @dragend="dragend" class="droppable-element" draggable="true"
             unselectable="on">Droppable Element (Drag me!)</div> -->
        <div id="content" class="container">
            <grid-layout
                ref="gridlayout"
                :margin="[parseInt(marginX), parseInt(marginY)]"
                :layout.sync="layout"
                :col-num="parseInt(colNum)"
                :max-rows="parseInt(maxRows)"
                :row-height="119"
                :is-draggable="true"
                :is-resizable="true"
                :responsive="true"
                :vertical-compact="true"
                :use-css-transforms="true"
                :prevent-collision="false"
                @layout-updated="layoutUpdatedEvent"
            >
                <grid-item
                    v-for="item in layout"
                    :key="item.i"
                    :x="item.x"
                    :y="item.y"
                    :w="item.w"
                    :min-w="2"
                    :max-w="2"
                    :min-h="1"
                    :max-h="2"
                    :h="item.h"
                    :i="item.i"
                >
                    <div @mousedown="mousedownHadle" @mouseup="mouseupHandle(item.val)" class="clickBox">
                        <isc-image :src="item.val.logo" :class="item.h === 2 ? 'bigItemImage' : 'smallItemIamge'">
                            <div slot="error" class="image-slot">
                                <isc-image src="/desktop/icon/default.png"></isc-image>
                            </div>
                        </isc-image>
                        <div :class="item.h === 2 ? 'bigItemTextBox' : 'samllItemTextBox'">
                            <span :class="item.h === 2 ? 'bigItemNameText' : 'smallItemNameText'">{{
                                item.val.name
                            }}</span>
                            <span v-if="item.h === 1" class="smallItemDescribeText">{{ item.val.describe }}</span>
                        </div>
                    </div>

                    <!-- <div class="vue-draggable-handle dragBbox"></div> -->
                    <span class="remove" @click="removeItem(item.i)">
                        <isc-svg-icon class="icon" name="iscon-bold-close" size="16px"></isc-svg-icon>
                    </span>
                </grid-item>
            </grid-layout>
        </div>
    </div>
</template>

<script>
import { bus } from '../../bus/bus';
import { GridItem, GridLayout } from './vue-grid-layout.common.js';
import { getDeskList, updateDeskList } from '../../service/common';
let mouseXY = { x: null, y: null };
let DragPos = { x: null, y: null, w: 2, h: 1, i: null };

export default {
    components: {
        GridLayout,
        GridItem
    },
    data() {
        return {
            defaultList: ['video', 'udmp', 'system-manage', 'permission', 'device-manage', 'twin-model', 'studio'],
            iconList: ['app-store', 'ias'], // 除了默认列表，有特殊图标的
            layout: [],
            layoutList: [
                {
                    x: 0,
                    y: 0,
                    w: 2,
                    h: 2
                },
                {
                    x: 2,
                    y: 0,
                    w: 2,
                    h: 2
                },
                {
                    x: 4,
                    y: 0,
                    w: 2,
                    h: 1
                },
                {
                    x: 4,
                    y: 1,
                    w: 2,
                    h: 1
                },

                {
                    x: 0,
                    y: 2,
                    w: 2,
                    h: 2
                },
                {
                    x: 2,
                    y: 2,
                    w: 2,
                    h: 2
                },
                {
                    x: 4,
                    y: 2,
                    w: 2,
                    h: 1
                },
                {
                    x: 4,
                    y: 3,
                    w: 2,
                    h: 1
                }
            ],
            draggable: true,
            resizable: true,
            mirrored: false,
            responsive: false,
            preventCollision: false,
            compact: true,
            rowHeight: 248,
            colNum: 6,
            maxRows: 4,
            index: 0,
            marginX: 10,
            marginY: 10,

            newItemSize: {
                w: 2,
                h: 1
            },

            defaultIconUrl: require(`../../assets/icon/default.png`),
            isClickEvent: true,
            timmerHandle: null
        };
    },
    computed: {
        allIconList() {
            return this.defaultList.concat(this.iconList);
        }
    },
    watch: {
        '$store.state.app.user.appList': {
            handler() {
                this.getLayoutList();
            },
            deep: true //true 深度监听
        }
    },
    created() {
        this.getLayoutList();
    },
    mounted() {
        document.addEventListener(
            'dragover',
            function (e) {
                mouseXY.x = e.clientX;
                mouseXY.y = e.clientY;
            },
            false
        );
        bus.$on('drag', (arg) => {
            this.drag(arg[0], arg[1]);
        });
        bus.$on('dragend', (e) => {
            this.dragend(e);
        });
    },
    beforeDestroy() {},
    methods: {
        async getLayoutList() {
            const appList = this.$store.state.app.user.appList;
            let { frontView } = await getDeskList();
            if (frontView) {
                frontView = JSON.parse(frontView);
                this.layout = frontView.filter((item) => appList.some((ele) => ele.id === item.i));
            } else {
                let arr = [];
                let list = appList.filter((item) => this.defaultList.indexOf(item.code) > -1);
                for (let i = 0; i < list.length; i++) {
                    arr.push({ ...this.layoutList[i], i: list[i].id, val: list[i] });
                }
                this.layout = arr;
            }
        },
        removeItem(val) {
            const index = this.layout.map((item) => item.i).indexOf(val);
            this.layout.splice(index, 1);
            this.layoutUpdate();
        },
        drag(e, val) {
            this.newElement = val;
            let parentRect = document.getElementById('content').getBoundingClientRect();
            let mouseInGrid = false;
            if (
                mouseXY.x > parentRect.left &&
                mouseXY.x < parentRect.right &&
                mouseXY.y > parentRect.top &&
                mouseXY.y < parentRect.bottom
            ) {
                mouseInGrid = true;
            }
            if (mouseInGrid === true && this.layout.findIndex((item) => item.i === 'drop') === -1) {
                this.layout.push({
                    x: (this.layout.length * 2) % (this.colNum || 12),
                    y: this.layout.length + (this.colNum || 12), // puts it at the bottom
                    w: this.newItemSize.w,
                    h: this.newItemSize.h,
                    i: 'drop',
                    val: val
                });
                return;
            }

            let index = this.layout.findIndex((item) => item.i === 'drop');

            if (index !== -1) {
                try {
                    this.$refs.gridlayout.$children[this.layout.length].$refs.item.style.display = 'none';
                    let el = this.$refs.gridlayout.$children[index];
                    el.dragging = { top: mouseXY.y - parentRect.top, left: mouseXY.x - parentRect.left };
                    let new_pos = el.calcXY(mouseXY.y - parentRect.top, mouseXY.x - parentRect.left);
                    if (mouseInGrid === true) {
                        this.$refs.gridlayout.dragEvent(
                            'dragstart',
                            'drop',
                            new_pos.x,
                            new_pos.y,
                            this.newItemSize.h,
                            this.newItemSize.w
                        );
                        DragPos.i = val.id;
                        DragPos.x = this.layout[index].x;
                        DragPos.y = this.layout[index].y;
                        // DragPos.val = this.layout[index].val;
                    }
                    if (mouseInGrid === false) {
                        this.$refs.gridlayout.dragEvent(
                            // 'dragend',
                            'drop',
                            new_pos.x,
                            new_pos.y,
                            this.newItemSize.h,
                            this.newItemSize.w
                        );
                        this.layout.splice(index, 1);
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        },
        dragend(e) {
            let parentRect = document.getElementById('content').getBoundingClientRect();
            let mouseInGrid = false;
            if (
                mouseXY.x > parentRect.left &&
                mouseXY.x < parentRect.right &&
                mouseXY.y > parentRect.top &&
                mouseXY.y < parentRect.bottom
            ) {
                mouseInGrid = true;
            }

            if (mouseInGrid === true) {
                try {
                    this.$refs.gridlayout.dragEvent(
                        // 'dragend',
                        'drop',
                        DragPos.x,
                        DragPos.y,
                        this.newItemSize.h,
                        this.newItemSize.w
                    );
                    let index = this.layout.findIndex((item) => item.i === 'drop');
                    this.layout.splice(index, 1);
                } catch (error) {
                    return;
                }

                // UNCOMMENT below if you want to add a grid-item
                if (this.layout.findIndex((item) => item.i === DragPos.i) === -1) {
                    if (
                        !this.addNewItem({
                            x: DragPos.x,
                            y: DragPos.y,
                            w: this.newItemSize.w,
                            h: this.newItemSize.h,
                            i: DragPos.i,
                            val: this.newElement
                        })
                    ) {
                        this.$refs.gridlayout.resetLayout();
                        this.$message({
                            message: '剩余空间不足请调整布局',
                            type: 'warning'
                        });
                        return;
                    }

                    try {
                        this.$refs.gridlayout.dragEvent(
                            'dragend',
                            DragPos.i,
                            DragPos.x,
                            DragPos.y,
                            this.newItemSize.h,
                            this.newItemSize.w
                        );
                        this.$refs.gridlayout.$children[this.layout.length].$refs.item.style.display = 'block';
                    } catch (e) {
                        console.error(e);
                    }
                } else {
                    this.$refs.gridlayout.resetLayout();
                    this.$message({
                        message: '桌面已存在此应用',
                        type: 'warning'
                    });
                }
            }
        },

        addNewItem(layoutItem) {
            if (this.getLayoutS() + layoutItem.w * layoutItem.h <= this.maxRows * this.colNum) {
                this.layout.push(layoutItem);

                return true;
            }
            return false;
        },

        getLayoutS() {
            let s = 0;
            for (let i = 0; i < this.layout.length; i++) {
                let l = this.layout[i];
                s += l.w * l.h;
            }
            return s;
        },

        layoutUpdatedEvent: function (newLayout) {
            if (newLayout[1] === 'dragend' || newLayout[1] === 'resizeend') {
                this.layoutUpdate();
                // 置为false, 阻止鼠标抬起触发点击事件
                this.isClickEvent--;
            }
            // if(newLayout[1] === 'resizeend'){
            //
            //      this.isClickEvent = false;
            // }
        },
        mouseupHandle(app) {
            if (this.isClickEvent > 0) {
                clearTimeout(this.timmerHandle);
                this.jumpUrl(app);
            }
            this.isClickEvent = 0;
        },
        mousedownHadle() {
            // TODO 帮忙实现下
            //延迟100ms
            this.isClickEvent = 1;
            // this.timmerHandle = setTimeout(() => (this.isClickEvent = false), 200);
        },
        jumpUrl(app) {
            this.$appManager.openApp(app.code);
            // TODO 这个功能，挪到了 app Manager 里边，之后没有问题可以删掉
            // let url =
            //     app.type === 3
            //         ? '/app/' + app.code
            //         : app.type === 0
            //         ? '/os/' + app.code
            //         : app.type === 5
            //         ? `/new-app?redirectUrl=${app.redirectUrl}`
            //         : '/' + app.code;
            // // // TODO 正式环境干掉
            // if (app.type === 99) {
            //     url = `/local-app/${app.code}`;
            // }
            // if (app.type === 5) {
            //     window.open(`${app.redirectUrl}`, '_blank');
            // }
            // this.$router.push(url);
            // return app.type === 3 ? '/app/' + app.code : '/' + app.code;
        },

        async layoutUpdate() {
            try {
                await updateDeskList({ frontView: JSON.stringify(this.layout) });
            } catch (error) {
                return;
            }
            // this.$emit('layout-updated', this.layout);
        }
    }
};
</script>

<style scoped lang="scss">
.bigItemImage {
    width: 128px;
    height: 128px;
    left: 60px;
    top: 45px;
    position: fixed;
}

.smallItemIamge {
    width: 64px;
    height: 64px;
    left: 16px;
    top: 28px;
    position: fixed;
}

.bigItemTextBox {
    position: relative;
    top: 190px;
    width: 100%;
    height: 50px;
}

.samllItemTextBox {
    position: relative;
    left: 88px;
    width: 144px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bigItemNameText {
    font-size: 20px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;
    letter-spacing: 4px;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
    text-align: center;
    position: fixed;
    top: 190px;
    width: 100%;
}

.smallItemNameText {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 16px;
    letter-spacing: 2px;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
}

.smallItemDescribeText {
    width: 144px;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 14px;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    margin: 5px 0 0 0;
}

.clickBox {
    position: fixed;
    width: 100%;
    height: 100%;
    &:hover + .remove .icon {
        display: block;
    }
}

#content {
    width: 802px;
    margin: 0 auto;
}

.droppable-element {
    width: 150px;
    text-align: center;
    background: #fdd;
    border: 1px solid black;
    margin: 10px 0;
    padding: 10px;
}
.remove {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 2px;
    top: 4px;
    cursor: pointer;
    color: #ffffff;
    .icon {
        display: none;
    }
    &:hover .icon {
        display: block;
    }
}
.vue-grid-layout {
    background: rgba(0, 0, 0, 0);
    touch-action: none;
    width: 784px;
}
.vue-grid-item:not(.vue-grid-placeholder) {
    background: rgba(166, 174, 185, 0.4);
    border: 2px solid;
    border-radius: 8px;
    border-color: rgba(166, 174, 185, 0.4);
    backdrop-filter: blur(5px);
    flex: 1;
}

.vue-grid-item .resizing {
    opacity: 0.9;
}
.vue-grid-item .static {
    background: #cce;
}

.vue-grid-item .vue-resizable-handle {
    z-index: 5;
}

.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
    visibility: hidden;
}

.vue-grid-item .minMax {
    font-size: 12px;
}
.vue-grid-item .add {
    cursor: pointer;
}
.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>")
        no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: move;
}
.layoutJSON {
    background: #ddd;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
}
.columns {
    -moz-columns: 120px;
    -webkit-columns: 120px;
    columns: 120px;
}

::v-deep .container .vue-grid-item.vue-grid-placeholder {
    background: #046ee6 !important;
}
</style>
